/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';

import {
  Container,
  Flex,
  Box,
  Text,
  Button,
  Modal,
  Popup
} from '@churni/styleguide';
import { Global } from '@emotion/core';
import * as querystring from 'querystring';
import { demo } from '../config';

export default function Home(props: any) {
  const params = querystring.parse(props.location.search.slice(1));

  const [confirmationModal, setConfirmationModal] = React.useState(
    params.status === 'canceled'
  );
  const [offerModal, setOfferModal] = React.useState(
    params.status === 'saved' && params.reason !== 'footer_link'
  );

  const churniURL = demo.churniURL;

  const appID = params.app || demo.prod;

  const [loading, setLoading] = React.useState(false);

  const customer = {
    id: 'customer-' + Math.random(),
    email: 'test@example.com',
    name: 'Acme Inc.',
    domain: 'example.com',
    createdAt: new Date('2019-01-01').getTime()
  };

  const onClick = async () => {
    setLoading(true);
    const res = await fetch(churniURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        appID, // Different between prod/testing envs

        // Redirect URLs when the user complete or quits the survey
        cancelConfirmationURL: demo.cancelConfirmationURL,
        saveReturnURL: demo.saveReturnURL,
        // Required fields to identify the customer
        customer,

        variables: {
          plan_type: 'pro' // optional variables which can be used in your survey
        }
      })
    });

    const { url } = await res.json();

    // Redirect to churni.io cancel session
    window.location.href = url;
    setLoading(false);
  };

  return (
    <>
      <Global
        styles={theme => {
          return {
            body: {
              fontFamily: theme.fonts.body,
              fontSize: theme.fontSizes[theme.text.body1.fontSize],
              fontWeight: theme.fontWeights[theme.text.body1.fontWeight],
              letterSpacing: theme.text.body1.letterSpacing,
              color: theme.colors.text,
              backgroundColor: theme.colors.background,
              margin: 0,
              padding: 0,
              overflowX: 'hidden',
              overflowY: 'scroll'
            }
          };
        }}
      />
      <Container>
        <Flex pt={5}>
          <Box sx={{ minHeight: '300px', width: 300 }}>
            <Box>
              <Text variant="display3">Pied Piper</Text>
            </Box>
            <Box
              mt={7}
              width={200}
              height={20}
              sx={{ bg: 'bgPlaceholderSecondary', borderRadius: 'radius' }}
            />
            <Box
              mt={2}
              width={200}
              height={20}
              sx={{ bg: 'bgPlaceholderSecondary', borderRadius: 'radius' }}
            />
            <Box
              mt={2}
              width={200}
              height={20}
              sx={{ bg: 'bgPlaceholderSecondary', borderRadius: 'radius' }}
            />
          </Box>
          <Box elevation={1} flex={1} p={5}>
            <Text variant="display4">Billing settings</Text>

            <Box mt={5}>
              <Box
                mt={2}
                width={400}
                height={20}
                sx={{ bg: 'bgPlaceholderSecondary', borderRadius: 'radius' }}
              />
              <Box
                mt={2}
                width={300}
                height={20}
                sx={{ bg: 'bgPlaceholderSecondary', borderRadius: 'radius' }}
              />
              <Box
                mt={2}
                width={500}
                height={20}
                sx={{ bg: 'bgPlaceholderSecondary', borderRadius: 'radius' }}
              />
            </Box>
            <Box mt={5}>
              <Button
                variant="primaryOutline"
                loading={loading}
                onClick={onClick}
              >
                Cancel my subscription
              </Button>
            </Box>
          </Box>
        </Flex>
        {offerModal && (
          <Popup>
            <Modal maxWidth={500}>
              <Modal.Header>Subscription updated</Modal.Header>
              <Modal.Body>
                <Box>
                  <Text variant="body">
                    We have applied a free month to your subscription. We'll
                    contact you to help you get started.
                  </Text>
                </Box>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="cancel" onClick={() => setOfferModal(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Popup>
        )}
        {confirmationModal && (
          <Popup>
            <Modal maxWidth={500}>
              <Modal.Header>Subscription canceled</Modal.Header>
              <Modal.Body>
                <Box>
                  <Text variant="body">
                    Your subscription has been set to been canceled at the end
                    of the blling cycle.
                  </Text>
                </Box>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="cancel"
                  onClick={() => setConfirmationModal(false)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Popup>
        )}
      </Container>
    </>
  );
}
